import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import { FaAward } from 'react-icons/fa'
import { FaGraduationCap } from "react-icons/fa";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} className='about-img' alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>1.5+ Years Working</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>20+ Completed</small>
            </article>

            <article className="about__card">
              <FaGraduationCap className="about__icon" />
              <h5>Education</h5>
              <small>Master's Degree</small>
            </article>
          </div>

          <p>
              Earned my Master's Degree in Computer Science from Griffith College Dublin in September 2023. 
              As a Software Developer, I have experience developing web applications and APIs and working with multiple programming languages and frameworks 
              such as C#/ASP.NET Framework/Core, Python (Django/Flask) and React.js/Angular 4+. I am also familiar with database management (SQL, NoSQL) and cloud computing platforms such as GCP and AWS.
              As a collaborative team player, I thrive in fast-paced environments and am committed to delivering high-quality work on time. It is my aim to continue improving 
              myself at this point and become a qualified full stack web developer.
          </p>

          <a href="#contact" className="btn btn-primary btn-talk">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
}

export default About